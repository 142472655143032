import React, { useReducer, useEffect } from "react"

import { IArticle, TArticleAction } from "../types"
import { getArticle } from "../api/article"

interface ArticleState {
  article: IArticle | undefined
  initialized: boolean
}

export function useArticleCollection(): [
  ArticleState,
  React.Dispatch<TArticleAction>
] {
  const articleReducer = (
    state: ArticleState,
    action: TArticleAction
  ): ArticleState => {
    switch (action.type) {
      case "fetch":
        return { ...state, initialized: true, article: action.payload.data }

      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(articleReducer, {
    article: undefined,
    initialized: false,
  })

  useEffect(() => {
    getArticle().then((article) => {
      dispatch({ type: "fetch", payload: { data: article } })
    })
  }, [])

  return [state, dispatch]
}
