import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { UserAvatar } from "../types"
import { maxRating } from "../constants"
import { STAR_EMPTY, STAR_FULL } from "../constants/images"
import { USER_1, USER_2, USER_3 } from "../constants/avatars"
import { useUsers } from "../providers/UserProvider"

const userAvatars: Record<UserAvatar, string> = {
  [UserAvatar.USER1]: USER_1,
  [UserAvatar.USER2]: USER_2,
  [UserAvatar.USER3]: USER_3,
}

export const Feedback = () => {
  function Arrow(props: any) {
    const { className, style, onClick } = props
    return (
      <div
        className={`${className} block rounded-full`}
        style={{
          ...style,
          display: "block",
          width: "30px",
          height: "30px",
          background: "rgb(134 25 143 / 0.75)",
        }}
        onClick={onClick}
      />
    )
  }
  const { users } = useUsers()

  return (
    <div className='mb-16'>
      <h2 className='text-fuchsia-800/75 text-2xl font-bold text-center md:text-3xl mb-5 py-16'>
        We've helped over 200,000 People create better, deeper, more meaningful
        relationships just see what they have to say about it!
      </h2>
      <Slider
        infinite={true}
        dots={true}
        arrows={true}
        autoplay={true}
        nextArrow={<Arrow />}
        prevArrow={<Arrow />}
        autoplaySpeed={8000}
        slidesToScroll={1}
        slidesToShow={3}
        responsive={[
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ]}
      >
        {users.map((user) => (
          <div key={`user-${user.name}`} className='relative pt-20 h-full'>
            <div className='flex items-center absolute top-2 left-2 sm:left-10 z-10'>
              <img src={userAvatars[user.url]} alt={user.name} />
              <div className='ml-4'>
                <div className='font-bold tracking-wider'>
                  {user.name.toUpperCase()}
                </div>
                <div>{user.address}</div>
              </div>
            </div>
            <div className='border border-fuchsia-800/75 border-2 h-full rounded-3xl p-5 md:p-8 z-0'>
              <div className='flex py-8'>
                {Array(user.rating)
                  .fill(1)
                  .map((item, index) => (
                    <div key={`rating-${index}`} className='mr-2'>
                      <img src={STAR_FULL} alt='star' />
                    </div>
                  ))}
                {Array(maxRating - user.rating)
                  .fill(1)
                  .map((item, index) => (
                    <div key={`rating-${index}`} className='mr-2'>
                      <img src={STAR_EMPTY} alt='white star' />
                    </div>
                  ))}
              </div>
              <div>{`"${user.content}"`}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
