import { VECTOR } from "../constants/images"

export const Header = () => {
  return (
    <div className='flex-wrap relative flex justify-center mb-10'>
      <div className='block ml-auto xl:ml-0 xl:absolute md:pl-10 pt-10 top-0 left-0 mr-auto mb-auto w-full xl:w-60'>
        <div className='flex justify-center'>
          <img src={VECTOR} alt='logo' />
          <div className='ml-5 w-40 font-bold leading-6 text-2xl'>
            Personal Development School
          </div>
        </div>
      </div>
      <div className='text-center pt-10 w-100'>
        <h3 className='font-bold text-3xl'>You Have A</h3>
        <h2 className='font-bold text-4xl'>
          Fearful Avoidant Attachment Style
        </h2>
        <p className='text-justify mt-3'>
          Check your email inbox to access your full report on your attachment
          style and read below to find out how to:
        </p>
      </div>
    </div>
  )
}
