import React, { useContext } from "react"

import { useArticleCollection } from "../hooks/useArticles"
import { IArticle, IArticleFetchAction } from "../types"

type ArticleContextType = {
  article: IArticle | undefined
  articleDispatch: React.Dispatch<IArticleFetchAction>
}
interface IProps {
  children: JSX.Element
}

export const ArticleContext = React.createContext<
  ArticleContextType | undefined
>(undefined)

export const ArticleProvider: React.FC<IProps> = ({ children }) => {
  const [{ initialized, article }, articleDispatch] = useArticleCollection()
  return (
    <ArticleContext.Provider value={{ article, articleDispatch }}>
      {initialized ? children : <div>loading...</div>}
    </ArticleContext.Provider>
  )
}

export const useArticles = () => {
  const articlesCtx = useContext(ArticleContext)
  if (!articlesCtx) {
    throw new Error("Component beyond ArticleContext!")
  }
  return articlesCtx
}
