import { useState } from "react"

import {
  ELLIPSE,
  WHITE_ELLIPSE,
  IMAGE_PLACEHOLDER_1,
  IMAGE_PLACEHOLDER_2,
  PLAY,
  VIDEO_PLACEHOLDER,
} from "../constants/images"
import { Video } from "./Video"

interface IProps {
  video: {
    current: string
  }
  className?: string
}

export const Introduction: React.FC<IProps> = ({ video, className }) => {
  const [play, setPlay] = useState(false)
  const handlePlay = () => {
    setPlay(true)
  }

  return (
    <>
      <div className={className}>
        <div className='border border-white rounded-lg md:rounded-3xl drop-shadow-xl shadow-lg border-[15px] md:border-[25px] relative'>
          <div className='absolute z-10 w-full h-full flex justify-center items-center'>
            <button
              onClick={handlePlay}
              className='absolute z-20 hover:opacity-80'
            >
              <img src={PLAY} alt='play button' className='w-20' />
            </button>
          </div>
          <img
            src={VIDEO_PLACEHOLDER}
            alt='video placeholder'
            className='w-full'
          />
        </div>
        <div className='relative h-[680px] lg:block hidden mt-20'>
          <img
            src={ELLIPSE}
            className='z-10 absolute top-0 right-0'
            alt='ellipse'
          />
          <img
            src={WHITE_ELLIPSE}
            className='bottom-0 absolute z-20'
            alt='white-ellipse'
          />
          <img
            src={IMAGE_PLACEHOLDER_1}
            className='absolute z-30 top-0 left-0'
            alt='placeholder 1'
          />
          <img
            src={IMAGE_PLACEHOLDER_2}
            className='absolute z-40 bottom-0 right-0'
            alt='placeholder 2'
          />
        </div>
        {play && <Video video={video} setPlay={setPlay} />}
      </div>
    </>
  )
}
