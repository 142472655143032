import React, { useContext } from "react"

import { useUsersCollection } from "../hooks/useUsers"
import { IUser, IUserFetchAction } from "../types"

type UserContextType = {
  users: IUser[]
  usersDispatch: React.Dispatch<IUserFetchAction>
}
interface IProps {
  children: JSX.Element
}

export const UserContext = React.createContext<UserContextType | undefined>(
  undefined
)

export const UserProvider: React.FC<IProps> = ({ children }) => {
  const [{ initialized, users }, usersDispatch] = useUsersCollection()
  return (
    <UserContext.Provider value={{ users, usersDispatch }}>
      {initialized ? children : <div>loading...</div>}
    </UserContext.Provider>
  )
}

export const useUsers = () => {
  const usersCtx = useContext(UserContext)
  if (!usersCtx) {
    throw new Error("Component beyond UserContext!")
  }
  return usersCtx
}
