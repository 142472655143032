import { useRef } from "react"

import { VIDEO_PLACEHOLDER } from "../constants/images"

interface IProps {
  video: {
    current: string
  }
  setPlay: (value: boolean) => void
}

export const Video: React.FC<IProps> = ({ video, setPlay }) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <>
      <div
        className='justify-center w-full items-center bg--fuchsia-800 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'
        onClick={() => setPlay(false)}
      >
        <div className='relative w-full mx-3 sm:mx-20 mx-auto max-w-screen-lg'>
          <div className='border-0 rounded-lg shadow-lg relative flex pb-4 px-4 flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-end border-b border-solid border-slate-200 rounded-t'>
              <button
                className='pb-1'
                onClick={() => setPlay(false)}
                data-testid='closeButton'
              >
                <span className='bg-transparent text-slate-500  h-6 w-6 text-2xl block outline-none focus:outline-none'>
                  ×
                </span>
              </button>
            </div>
            <video
              controls
              className='w-full'
              ref={videoRef}
              autoPlay
              data-testid='introVideo'
              poster={VIDEO_PLACEHOLDER}
            >
              <source src={video.current} type='video/mp4' />
            </video>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  )
}
