import { useArticles } from "../providers/ArticleProvider"

export const Article = () => {
  const { article } = useArticles()

  return (
    <div className='pt-10'>
      <div className='font-bold'>
        <div className='text-fuchsia-800/75 text-4xl md:text-5xl mb-5'>
          {article?.title}
        </div>
        <div className='mb-8 md:text-xl'>{article?.description}</div>
        <div className='mb-12 text-xl md:text-2xl'>{article?.subtitle}</div>
      </div>
      <div>
        <div>
          {article?.situations.map((situation) => (
            <div key={`situation-${situation.title}`} className='mb-5'>
              <div className='tracking-widest font-bold mb-3 md:text-xl'>
                {situation.title.toUpperCase()}
              </div>
              <div>{situation.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
