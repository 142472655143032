export interface IArticle {
  title: string
  description: string
  subtitle: string
  situations: ISituation[]
}

export interface ISituation {
  title: string
  content: string
}

export interface IArticleFetchAction {
  type: "fetch"
  payload: {
    data: IArticle
  }
}

export type TArticleAction = IArticleFetchAction

export interface IUser {
  name: string
  address: string
  content: string
  url: UserAvatar
  rating: number
}

export enum UserAvatar {
  USER1 = "USER1",
  USER2 = "USER2",
  USER3 = "USER3",
}

export interface IUserFetchAction {
  type: "fetch"
  payload: {
    data: IUser[]
  }
}

export type TUserAction = IUserFetchAction
