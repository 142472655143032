import React, { useReducer, useEffect } from "react"

import { IUser, TUserAction } from "../types"
import { getUsers } from "../api/users"

interface UserState {
  users: IUser[]
  initialized: boolean
}

export function useUsersCollection(): [UserState, React.Dispatch<TUserAction>] {
  const usersReducer = (state: UserState, action: TUserAction): UserState => {
    switch (action.type) {
      case "fetch":
        return { ...state, initialized: true, users: action.payload.data }

      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(usersReducer, {
    users: [],
    initialized: false,
  })

  useEffect(() => {
    getUsers().then((users) => {
      dispatch({ type: "fetch", payload: { data: users } })
    })
  }, [])

  return [state, dispatch]
}
