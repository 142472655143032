import { useRef } from "react"

import { Article } from "./components/Article"
import { Feedback } from "./components/Feedback"
import { Header } from "./components/Header"
import { Introduction } from "./components/Introduction"
import { ArticleProvider } from "./providers/ArticleProvider"
import { UserProvider } from "./providers/UserProvider"

export default function App() {
  const videoUrl = useRef<string>(
    "https://storage.googleapis.com/pds_videos/RoyalRumbleFAshortvideo.mp4"
  )

  return (
    <div className='px-5 sm:px-20'>
      <Header />
      <div className='container mx-auto'>
        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-20'>
          <div className='lg:order-1 order-2'>
            <ArticleProvider>
              <Article />
            </ArticleProvider>
          </div>
          <Introduction className='lg:order-2 order-1' video={videoUrl} />
        </div>
        <UserProvider>
          <Feedback />
        </UserProvider>
        <div className='text-center md:block hidden'>
          <button className='rounded-full text-white font-bold py-2 mb-16 px-52 hover:bg-fuchsia-800/70 bg-fuchsia-800/90'>
            START TODAY!
          </button>
        </div>
      </div>
    </div>
  )
}
